// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-oferta-pomoc-psychologiczna-js": () => import("./../../../src/pages/oferta/pomoc-psychologiczna.js" /* webpackChunkName: "component---src-pages-oferta-pomoc-psychologiczna-js" */),
  "component---src-pages-oferta-pomoc-seksuologiczna-js": () => import("./../../../src/pages/oferta/pomoc-seksuologiczna.js" /* webpackChunkName: "component---src-pages-oferta-pomoc-seksuologiczna-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-warsztaty-js": () => import("./../../../src/pages/warsztaty.js" /* webpackChunkName: "component---src-pages-warsztaty-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-pages-zespol-julianna-js": () => import("./../../../src/pages/zespol/julianna.js" /* webpackChunkName: "component---src-pages-zespol-julianna-js" */),
  "component---src-pages-zespol-justyna-js": () => import("./../../../src/pages/zespol/justyna.js" /* webpackChunkName: "component---src-pages-zespol-justyna-js" */),
  "component---src-pages-zespol-maciej-js": () => import("./../../../src/pages/zespol/maciej.js" /* webpackChunkName: "component---src-pages-zespol-maciej-js" */)
}

